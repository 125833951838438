// EvnInput.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Chip,
    TextField,
    Button,
    Snackbar,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Autocomplete
} from '@mui/material';
import Grid from '@mui/system/Grid';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { getDM } from './getDM';
import { getQueue } from './getQueue';

const StyledContainer = styled(Container)(({ theme }) => ({
    background: 'white',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8)
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginTop: '24px'
}));

const EvnInput = () => {
    // Convert class state to individual state hooks
    const [queueButton, setQueueButton] = useState('Sample');
    const [severity, setSeverity] = useState('');
    const [snackbarText, setSnackbarText] = useState('');
    const [dataModel, setDataModel] = useState({});
    const [textInput, setTextInput] = useState({
        alert_title: '',
        context: {
            action: [],
            related_actions: [],
            account: [],
            src_account: [],
            dest: [],
            src: [],
            program: [],
            parent_program: [],
            data: [],
        },
        disposition: '',
        incident: null
    });
    const [open, setOpen] = useState(false);

    // Replace componentDidMount with useEffect
    useEffect(() => {
        getDMC();
    }, []); // Empty dependency array = run once on mount

    // Convert class methods to component functions
    const post = async (uri, data) => {
        const res = await axios({
            method: 'post',
            url: `/api/${uri}`,
            headers: {
                'context-type': 'application/json',
            },
            data: data,
        });
        return res;
    };

    const userDetails = async () => {
        const response = await fetch('/.auth/me');
        if (!response.ok) {
            return;
        }
        const res = await response.json();
        return {
            userId: res.clientPrincipal.userId,
            userName: res.clientPrincipal.userDetails,
        };
    };

    const sendtoDb = async () => {
        const userInfo = await userDetails();
        const time = new Date();
        const data = {
            sample: {
                alert: textInput,
                user: userInfo,
                time: time.toISOString()
            }
        };
        const response = await post('sample/add/context-samples', data);
        
        if (response.status === 200) {
            setSeverity('success');
            setSnackbarText('Success, Thanks for assist!');
        } else {
            setSeverity('error');
            setSnackbarText('Failed to submit!');
        }
        
        setOpen(true);
        setTextInput(prev => ({
            ...prev,
            incident: null
        }));
    };

    const getQue = async () => {
        setTextInput(prev => ({
            ...prev,
            alert_title: ''
        }));
        setQueueButton('Next');
        
        const queuemsg = await getQueue();
        if (queuemsg) {
            setTextInput(queuemsg);
        }
    };

    const getDMC = async () => {
        const dm = await getDM();
        setDataModel(dm);
    };

    const handleChange = (_event, newValue, key) => {
        const t = newValue.filter(e => dataModel[key].includes(e));
        
        setTextInput(prev => ({
            ...prev,
            context: {
                ...prev.context,
                [key]: t
            }
        }));
    };

    const handleTitleChange = (event) => {
        setTextInput(prev => ({
            ...prev,
            alert_title: event.target.value
        }));
    };

    const handleDispositionChange = (event) => {
        setTextInput(prev => ({
            ...prev,
            disposition: event.target.value
        }));
    };

    const handleIncidentChange = (event) => {
        setTextInput(prev => ({
            ...prev,
            incident: event.target.value
        }));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const clearInputState = () => {
        setTextInput({
            alert_title: '',
            context: {
                action: [],
                related_actions: [],
                account: [],
                src_account: [],
                dest: [],
                src: [],
                program: [],
                parent_program: [],
                data: [],
            },
            disposition: '',
            incident: null
        });
    };

    // Render methods
    const renderTitleInput = () => (
            <Grid size={12}>
                <TextField
                    multiline
                    label="Alert Title"
                    variant='standard'
                    sx={{width: '45%'}}
                    value={textInput.alert_title}
                    onChange={handleTitleChange}
                />
            </Grid>
    );

    const renderDispositionInput = () => (
        <Grid size={8}>
            <TextField fullWidth
                multiline
                variant='standard'
                label="Alert Disposition Description"
                value={textInput.disposition}
                onChange={handleDispositionChange}
            />
        </Grid>
    );

    const renderIncidentInput = () => (
        <Grid size={4}>
            <FormControl fullWidth>
                <InputLabel htmlFor="disposition_select">Disposition</InputLabel>
                <Select
                    id="disposition_select"
                    label="Disposition"
                    variant='standard'
                    value={textInput.incident}
                    onChange={handleIncidentChange}
                >
                    <MenuItem value={0}>False Positive</MenuItem>
                    <MenuItem value={1}>Incident</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );

    const renderInputs = () => {
        const fields = Object.keys(dataModel).map((k) => (
            <Grid size={{lg:4, sx:12}} key={k + '_key'}>
                <Autocomplete
                    multiple
                    selectOnFocus
                    clearOnBlur
                    autoHighlight
                    disableClearable
                    value={textInput.context[k]}
                    options={dataModel[k]}
                    sx={{ width: '100%', ml: 1 }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={option + '_' + k + '_input'}
                                variant="outlined"
                                label={option}
                                sx={{ bgcolor: '#F1F8FE' }}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={k.replace('_', ' ')}
                        />
                    )}
                    onChange={(event, newValue) =>
                        handleChange(event, newValue, k)
                    }
                />
            </Grid>
        ));

        return (
            <>
                {fields}
                <Grid size={12} sx={{ p: 2 }} />
                {renderIncidentInput()}
                {renderDispositionInput()}
                <Grid size={12} sx={{ p: 2 }} />
                {renderSubmit()}
            </>
        );
    };

    const renderSubmit = () => {
        const contextCount = Object.values(textInput.context)
            .reduce((sum, arr) => sum + arr.length, 0);

        const submitEnabled = textInput.incident != null 
            && contextCount >= 3 
            && textInput.disposition !== '';

        const buttonStyle = {
            float: 'right',
            mr: 1,
            borderColor: '#1996a8',
            borderWidth: 2,
            color: '#1996a8',
            fontWeight: 'bold',
        };

        return (
            <>
                {submitEnabled && (
                    <Grid size={{xs:4, md:2}}>
                        <Button
                            id="submit"
                            variant="outlined"
                            sx={buttonStyle}
                            onClick={sendtoDb}
                        >
                            Submit
                        </Button>
                    </Grid>
                )}
                <Grid size={{xs:4, md:2}}>
                    <Button
                        id="clear"
                        variant="outlined"
                        sx={buttonStyle}
                        onClick={clearInputState}
                    >
                        Clear
                    </Button>
                </Grid>
                <Grid size={12} sx={{ p: 1 }} />
            </>
        );
    };

    return (
        <StyledContainer>
            <StyledGrid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 1, lg: 1 }}
                justifyContent="center"
                alignItems="center"
            >
                <Grid sx={{ mb: 2 }}>
                    <Typography variant="h4">
                        Welcome to the Salem Data Cannon
                    </Typography>
                    <Typography variant="body1">
                        Use this tool to create training data for Salem AI's models. 
                        Ensure that the input provided represents realistic alert scenarios.
                    </Typography>
                </Grid>
                {renderTitleInput()}
                {renderInputs()}
            </StyledGrid>
            
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert
                    severity={severity}
                    onClose={handleClose}
                >
                    {snackbarText}
                </Alert>
            </Snackbar>
        </StyledContainer>
    );
};

export default EvnInput;