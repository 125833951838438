export async function getDM() {
    const response = await fetch('/api/dm')
    if (!response.ok) {
        return
    }
    
    const dataModel = await response.json()
    const dm = {}
    Object.keys(dataModel).forEach((e) => {
        dm[e] = []
        dataModel[e].forEach((ele) => {
            dm[e] = dm[e].concat(ele)
        })
    })
    return dm
}
