/* eslint-disable react/prop-types */
import React from 'react'
import axios from 'axios'
import {
    Container,
    Box,
    Chip,
    TextField,
    Typography,
    Button, 
    List,
    ListItem,
    ListItemText,
    Autocomplete,
    Paper
} from '@mui/material'
import Grid from '@mui/system/Grid';
import { styled } from '@mui/material/styles'
import { getDM } from './getDM'


const CONTEXT_THRESHOLD = 1

const sampleContext = {
    action: [{label: "web", selected: 1},
    {label: "network_connection", selected: 1},
    {label: "success", selected: 1},
    {label: "typical_action", selected: 0},
    {label: "api", selected: 0},
    {label: "encrypted_protocol", selected: 0}],
    account: [{label: "regular user", selected: 1}],
    src: [{label: "workstation", selected: 1},
    {label: "windows", selected: 1},
    {label: "internal_resource", selected: 1}],
    dest: [{label: "IP_IOC", selected: 1},
    {label: "external_resource", selected: 0}]
}

const getBaseAlert = () => ({
    context: {},
    prediction: -1,
    needed_context: []
});

const Validator2 = () => {
    const [alert, setAlert] = React.useState(getBaseAlert());
    const [dm, setDM] = React.useState({});

    React.useEffect(() => {
        const getDMC = async () => {
            const get_dm = await getDM()
            setDM(get_dm)
        }
        getDMC()
    }, [])
    // Create styled components for commonly used styles
    const RootContainer = styled(Container)(({ theme }) => ({
        backgroundColor: 'white',
        minHeight: '100%'
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
        borderColor: '#1996a8',
        borderWidth: 2,
        color: '#1996a8',
        fontWeight: 'bold',
        marginRight: theme.spacing(1)
    }));

    const PredictionBar = styled('div')(({ theme }) => ({
        position: 'relative',
        marginTop: theme.spacing(4),
        width: '100%',
        height: '8px'
    }));

    const BarBackground = styled('div')({
        height: '8px',
        backgroundColor: '#efefef',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0
    });

    const BarFill = styled('div')({
        height: '8px',
        backgroundColor: '#4396a9',
        position: 'absolute',
        top: 0,
        left: 0,
    });

    const handleChange = (_event, newValue, key) => {
        var t = []
        newValue.forEach((e) => {
            if (dm[key].includes(e)) {
                t = t.concat([e])
            } 
        })
        let elm = { ...alert } 
        elm.context[key] = t
        setAlert(elm)
    };

    const handleSubmit = async () => {
        
        let res = await post('predict', {alert: alert})
        let alert_update = { ...alert }
        alert_update.prediction = Math.floor(res.prediction[0] * 100)
        alert_update.needed_context = res.needed_context
        setAlert(alert_update)
    }

    const handleClear = () => {
        setAlert(getBaseAlert());
    };

    const post = async (uri, data) => {
        const res = await axios({
            method: 'post',
            url: `/api/${uri}`,
            headers: {
                'context-type': 'application/json',
            },
            data: data,
        })
        let body = await res.data
        return body
    }

    const renderInputs = () => {
        const fields = Object.keys(dm).map((k) => (
            <Grid size={{ lg:4, xs:12}} key={k + '_key'}>
                <Autocomplete
                    multiple
                    selectOnFocus
                    clearOnBlur
                    autoHighlight
                    disableClearable
                    value={alert.context[k] || []}
                    options={dm[k] || []}
                    sx={{ width: '100%', ml: 1 }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={`${option}_${k}_input`}
                                variant="outlined"
                                label={option}
                                sx={{ bgcolor: '#F1F8FE' }}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={k.replace('_', ' ')}
                        />
                    )}
                    onChange={(event, newValue) =>
                        handleChange(event, newValue, k)
                    }
                />
            </Grid>
        ));

        return fields;
    };

    const prediction = () => (
        <Box sx={{ position: 'relative', width: '100%', mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>Threat likelihood: </Typography>
            <Typography sx={{ fontWeight: 500 }}>
                {alert.prediction <= 0 
                    ? 'N/A' 
                    : alert.prediction <= 35 
                        ? 'Low' 
                        : alert.prediction <= 70 
                            ? 'Medium' 
                            : 'High'}
            </Typography>
            </Box>
            <PredictionBar>
                <BarBackground />
                <BarFill 
                    sx={{ 
                        width: alert.prediction <= 0 
                            ? '1%' 
                            : `${alert.prediction}%` 
                    }} 
                />
            </PredictionBar>
        </Box>
    );

    const neededContext = () => (
        <List>
            {alert.needed_context.map((item, index) => (
                <ListItem key={index}>
                    <ListItemText primary={item} />
                </ListItem>
            ))}
        </List>
    );

    const renderSubmit = () => {
        const contextCount = Object.values(alert.context)
            .reduce((sum, arr) => sum + (arr?.length || 0), 0);

        return (
            <StyledButton
                id="submit"
                variant="outlined"
                disabled={contextCount < CONTEXT_THRESHOLD}
                onClick={() => handleSubmit()}
            >
                Submit
            </StyledButton>
        );
    };

    const renderClear = () => {
        return (
            <StyledButton
                id="clear"
                variant="outlined"
                onClick={() => handleClear()}
                aria-label="Clear form"
            >
                Clear
            </StyledButton>
        );
    };

    return (
        <RootContainer maxWidth="lg">
            <Box sx={{ 
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ flex: 1 }}>
                    <Grid
                        container 
                        spacing={3}
                        sx={{ py: 4 }}
                    >
                        {/* Header */}
                        <Grid size={12}>
                            <Typography variant="h6">
                                Ask Salem to review a cyber alert
                            </Typography>
                        </Grid>

                        {/* Input Fields */}
                        {renderInputs()}

                        {/* Prediction Section */}
                        <Grid size={8}>
                            {prediction()}
                        </Grid>
                        <Grid item size={4} sx={{ 
                            display: 'flex', 
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end' 
                        }}>
                            {renderSubmit()}
                            {renderClear()}
                        </Grid>

                        {/* Needed Context Section */}
                        <Grid size={12}>
                            {alert.needed_context?.length > 0 && (
                                <Paper sx={{ p: 2, mt: 2 }}>
                                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                        Additional Context Needed
                                    </Typography>
                                    {neededContext()}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </RootContainer>
    );
};

export default Validator2