import Salem1 from '../Images/Salem1.png'
import { Box, Button, AppBar, Toolbar } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    float: 'right',
    color: 'black',
}))

const StyledHeader = styled(AppBar)(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: '#f1f8fe',
    width: '100%',
    position: 'sticky',
}))

const ButtonContainer = styled(Box)({
    marginLeft: 'auto', // This pushes the buttons to the right
    display: 'flex',
    alignItems: 'center',
})

const login_button = (auth) => {
    if (auth) {
        return <StyledButton href="/logout"> logout </StyledButton>
    } else {
        return <StyledButton href="/login"> login </StyledButton>
    }
}

const dc_button = (auth) => {
    if (auth) {
        return (
            <>
                <StyledButton href="/cannon"> Data Cannon </StyledButton>
                <StyledButton href="/validator2"> Validator </StyledButton>
            </>
        )
    } else {
        return null;
    }
}

const Nav = () => {
    const [auth, setAuth] = React.useState(0)

    React.useEffect(() => {
        const get_user = async () => {
            const response = await fetch('/.auth/me')
            const user = await response.json()
            if (
                user.clientPrincipal &&
                user.clientPrincipal.userRoles.includes('authenticated')
            ) {
                setAuth(1)
                await fetch('/api/hi')
            } else {
                setAuth(0)
            }
        }
        get_user(setAuth).catch()
    }, [])
    return (
        <StyledHeader>
            <Toolbar variant="dense">
                <img
                    src={Salem1}
                    href="/"
                    alt="company logo"
                    width={182}
                    height={64}
                />
                <ButtonContainer>
                    {dc_button(auth)}
                    {login_button(auth)}
                </ButtonContainer>
            </Toolbar>
        </StyledHeader>
    )
}

export default Nav
